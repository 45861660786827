<template>
  <el-transfer
    ref="transfer"
    :titles="titles"
    v-model="value"
    :data="data"
    @change="handleChange"
    :render-content="rendercontent"
  ></el-transfer>
</template>
<script>
export default {
  props: {
    titles: {
      type: Array,
    },
    data: {
      type: Array,
    },
  },
  data() {
    return {
      value: [],
    };
  },
  methods: {
    clearvalue() {
      this.value = [];
    },
    handleChange(value, direction, movedKeys) {
      this.$emit("transferchange", value, direction, movedKeys);
    },
    rendercontent(h, option) {
      if (option.label == "矩阵版") {
        var data = (
          <div>
            <div>矩阵版</div>
            {option.matrilist.map((item) => {
              return <div>{`${item.Name}(${item.Id})`}</div>;
            })}
          </div>
        );
        return data;
      } else {
        return (
          <div>
            <span>{option.label}</span>
          </div>
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-transfer-panel__body {
  height: 100%;
  overflow: auto;
}
::v-deep .el-transfer-panel__list {
  height: 100%;
  overflow: auto;
}
::v-deep .el-transfer-panel__item {
  height: auto;
}
</style>