<template>
  <div class="subaccount-wrap">
    <div class="bread-wrap">
      <span class="bread-title">子账号列表</span>
    </div>
    <div class="content">
      <div class="list-top">
        <el-button @click="showcreatemodel" type="primary" class="creategroup"
          >创建子账号</el-button
        >
        <el-button @click="setSubPasswordAll" type="primary" class="creategroup"
          >统一重置密码</el-button
        >
        <el-button size="mini" @click="refresh" type="primary" class="refresh"
          >刷新</el-button
        >
        <el-button
          @click="handleSearch"
          class="searchbtn"
          size="mini"
          type="primary"
          >搜索</el-button
        >
        <el-input
          class="searchinput"
          placeholder="请输入搜索内容"
          size="mini"
          v-model.trim="searchvalue"
          @keyup.enter.native="handleSearch"
          clearable
        />
      </div>
      <div class="screen" v-if="issearch">
        <div class="screen-title">
          共{{ this.tabledata.length }}条数据<el-divider
            direction="vertical"
          ></el-divider
          >筛选条件：{{ this.searchvalue }}
        </div>
        <el-button size="mini" type="primary" class="clear" @click="refresh"
          >清空</el-button
        >
      </div>
      <com-table
        v-loading="tableloading"
        class="grouplist"
        :headercellstyle="headercellstyle"
        :columns="subaccountlist"
        :pagination="pagination"
        tableheight="calc(100vh - 350px)"
        :data="tabledata"
      >
        <el-table-column
          align="center"
          header-align="center"
          slot="action"
          label="操作"
          min-width="150"
        >
          <template slot-scope="scope">
            <el-button
              @click="deletesub(scope.row)"
              class="deletebtn"
              size="mini"
              type="primary"
              >删除</el-button
            >
            <el-dropdown trigger="click">
              <el-button class="morebtn" size="mini" type="primary"
                >分发</el-button
              >
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click.native="distribute(scope.row)"
                    >通用云手机</el-dropdown-item
                  >
                  <el-dropdown-item v-if="ShowMatrixPhone" @click.native="distributeMatrix(scope.row)"
                    >矩阵云手机</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="distributeLine(scope.row)"
                    >直播快</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-dropdown trigger="click">
              <el-button class="morebtn" size="mini" type="primary"
                >回收</el-button
              >
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click.native="regain(scope.row)"
                    >通用云手机</el-dropdown-item
                  >
                  <el-dropdown-item v-if="ShowMatrixPhone" @click.native="regainMatrix(scope.row)"
                    >矩阵云手机</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="regainLine(scope.row)"
                    >直播快</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <!-- <el-button
              @click="distribute(scope.row)"
              class="resetbtn"
              size="mini"
              type="primary"
              >分发</el-button
            > -->
            <!-- <el-button
              @click="regain(scope.row)"
              class="resetbtn"
              size="mini"
              type="primary"
              >回收</el-button
            > -->
            <el-dropdown trigger="click" :hide-on-click="false">
              <el-button class="morebtn" size="mini" type="primary"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <p @click="modifyname(scope.row)">修改昵称</p>
                </el-dropdown-item>
                <el-dropdown-item>
                  <p @click="modifypassword(scope.row)">重置密码</p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </com-table>
    </div>
    <create-sub
      :showcreate="showcreate"
      title="创建子账号"
      @closecreate="closecreate"
      :row="row"
      :phonenumber="suffix"
    />
    <delete-sub
      :showdelete="showdelete"
      title="删除子账号"
      :row="row"
      @closedelete="closedelete"
    />
    <edite-sub
      :showedite="showedite"
      :editepassword="editepassword"
      :row="row"
      :title="title"
      @closemodify="closemodify"
    />
    <!-- <distribute
      :showdistribute="showdistribute"
      title="分发云手机到子账号"
      :row="row"
      @closedistribute="closedistribute"
      :phoneInfos="phoneInfos"
    /> -->
    <!-- <regainsub
      :showregain="showregain"
      title="回收云手机到主账号"
      :row="row"
      @closeregin="closeregin"
      :subphoneInfos="subphoneInfos"
    /> -->
    <distribute-line
      :showdistribute="showdistributeline"
      title="分发线路到子账号"
      :row="row"
      @closedistributeline="closedistributeline"
      :lineInfos="lineInfos"
    />
    <regainsub-line
      :showregain="showregainline"
      title="回收线路到主账号"
      :row="row"
      @closereginline="closereginline"
      :sublineInfos="sublineInfos"
    />
    <edit-password-all
      :editPasswordAll="editPasswordAll"
      @closeEditPasswordAll="closeEditPasswordAll"
    />

    <group-distribute-phone
      :showdistribute="showdistribute"
      title="分发通用云手机到子账号"
      :row="row"
      @closedistribute="closedistribute"
      :phoneInfos="phoneInfos"
    />

    <group-regainsub-phone
      :showregain="showregain"
      title="回收通用云手机到主账号"
      :row="row"
      @closeregin="closeregin"
      :subphoneInfos="subphoneInfos"
    />

    <group-distribute-matrix-phone
      :showdistribute="showdistributematrix"
      title="分发矩阵云手机到子账号"
      :row="row"
      @closedistribute="closedistributematrix"
      :phoneInfos="phoneInfos"
    />

    <group-regainsub-matrix-phone
      :showregain="showregainmatrix"
      title="回收矩阵云手机到主账号"
      :row="row"
      @closeregin="closereginmatrix"
      :subphoneInfos="subphoneInfos"
    />
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import CreateSub from "./Models/CreateSub";
import DeleteSub from "./Models/DeleteSub";
import EditeSub from "./Models/EditeSub";
import Distribute from "./Models/Distribute";
import Regainsub from "./Models/Regainsub";
import { subaccountlist } from "@/utils/tableList";
import DistributeLine from "./Models/DistributeLine";
import RegainsubLine from "./Models/RegainsubLine.vue";
import EditPasswordAll from "./Models/EditPasswordAll.vue";
import GroupDistributePhone from "./Models/GroupDistributePhone";
import GroupRegainsubPhone from "./Models/GroupRegainsubPhone";
import GroupDistributeMatrixPhone from "./Models/GroupDistributeMatrixPhone";
import GroupRegainsubMatrixPhone from "./Models/GroupRegainsubMatrixPhone";

export default {
  data() {
    return {
      subaccountlist,
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      tabledata: [],
      title: "",
      showcreate: false,
      row: {},
      showdelete: false,
      showedite: false,
      editepassword: false,
      showdistribute: false,
      showdistributematrix: false,
      showregain: false,
      showdistributeline: false,
      showregainline: false,
      showregainmatrix: false,
      editPasswordAll: false,
      phoneInfos: [],
      subphoneInfos: [],
      lineInfos: [],
      sublineInfos: [],
      tableloading: false,
      phonenumber: "",
      suffix: "",
      searchvalue: "",
      issearch: false,
      ShowMatrixPhone: false,
    };
  },
  components: {
    ComTable,
    CreateSub,
    DeleteSub,
    EditeSub,
    Distribute,
    Regainsub,
    DistributeLine,
    RegainsubLine,
    EditPasswordAll,
    GroupDistributePhone,
    GroupRegainsubPhone,
    GroupDistributeMatrixPhone,
    GroupRegainsubMatrixPhone,
  },
  methods: {
    getsubaccountlist() {
      this.tableloading = true;
      this.$store.dispatch("sub/getsublist").then((res) => {
        if (res.RetCode == 0) {
          this.tableloading = false;
          this.tabledata = res.UserInfos;
        } else {
          this.tableloading = false;
          this.$message.error("加载异常");
        }
      });
    },
    getphonelist(SubUserId) {
      if (SubUserId) {
        this.$store
          .dispatch("group/getphonelist", { SubUserId })
          .then((res) => {
            if (res.RetCode == 0) {
              this.subphoneInfos = res.PhoneInfos;
            }
          });
      } else {
        this.$store.dispatch("sub/getphonelist").then((res) => {
          if (res.RetCode == 0) {
            this.phoneInfos = res.PhoneInfos;
          }
        });
      }
    },
    getlinelist(SubUserId) {
      if (SubUserId) {
        this.$store
          .dispatch("line/getlinelist", { subUserId: SubUserId })
          .then((res) => {
            if (res.retCode == 0) {
              this.sublineInfos = res.lineInfos;
            }
          });
      } else {
        this.$store.dispatch("line/getlinelist").then((res) => {
          if (res.retCode == 0) {
            this.lineInfos = res.lineInfos;
          }
        });
      }
    },
    refresh() {
      this.getsubaccountlist();
      this.issearch = false;
      this.searchvalue = "";
    },
    handleSearch() {
      if (!this.searchvalue) {
        return;
      }
      let list = this.tabledata.filter((item, index) => {
        if (
          item.UserId.includes(this.searchvalue) ||
          item.Username.includes(this.searchvalue) ||
          item.NickName.includes(this.searchvalue)
        ) {
          return true;
        }
      });
      this.tabledata = list;
      this.issearch = true;
    },
    showcreatemodel() {
      if (this.phonenumber == "") {
        this.$message.error("获取账号信息失败");
        return;
      }
      this.showcreate = !this.showcreate;
      this.title = "创建子账号";
    },
    closecreate() {
      this.showcreate = !this.showcreate;
      this.getsubaccountlist();
    },
    closedelete() {
      this.getsubaccountlist();
      this.showdelete = !this.showdelete;
    },
    closemodify() {
      this.getsubaccountlist();
      this.showedite = !this.showedite;
      this.editepassword = false;
    },
    closedistribute() {
      this.showdistribute = !this.showdistribute;
    },
    closedistributematrix() {
      this.showdistributematrix = !this.showdistributematrix;
    },
    closeregin() {
      this.showregain = !this.showregain;
    },
    closereginmatrix() {
      this.showregainmatrix = !this.showregainmatrix;
    },
    closedistributeline() {
      this.showdistributeline = !this.showdistributeline;
    },
    closereginline() {
      this.showregainline = !this.showregainline;
    },
    deletesub(row) {
      this.row = row;
      this.showdelete = !this.showdelete;
    },
    modifyname(row) {
      this.row = row;
      this.title = "修改昵称";
      this.showedite = !this.showedite;
    },
    modifypassword(row) {
      this.row = row;
      this.title = "修改密码";
      this.editepassword = !this.editepassword;
      this.showedite = !this.showedite;
    },
    setSubPasswordAll() {
      this.editPasswordAll = !this.editPasswordAll;
    },
    closeEditPasswordAll() {
      this.editPasswordAll = !this.editPasswordAll;
    },
    distribute(row) {
      this.getphonelist();
      this.row = row;
      this.showdistribute = !this.showdistribute;
    },

    distributeMatrix(row) {
      this.getphonelist();
      this.row = row;
      this.showdistributematrix = !this.showdistributematrix;
    },

    regain(row) {
      this.getphonelist(row.UserId);
      this.row = row;
      this.showregain = !this.showregain;
    },
    regainMatrix(row) {
      this.getphonelist(row.UserId);
      this.row = row;
      this.showregainmatrix = !this.showregainmatrix;
    },

    distributeLine(row) {
      this.getlinelist();
      this.row = row;
      this.showdistributeline = !this.showdistributeline;
    },
    regainLine(row) {
      console.log(row.UserId);
      this.getlinelist(row.UserId);
      this.row = row;
      this.showregainline = !this.showregainline;
    },
    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
          this.ShowMatrixPhone = res.ShowMatrixPhone;
        }
      });
    },
  },
  mounted() {
    this.getsubaccountlist();
    this.$store.dispatch("user/getuserinfo").then((res) => {
      // console.log("get user info: " + JSON.stringify(res));
      if (res.RetCode == 0) {
        if (res.Username == res.UserId) {
          this.suffix = res.PhoneNumber;
        } else {
          this.suffix = res.Username;
        }
        this.phonenumber = res.PhoneNumber;
      }
    });
    this.getuserinfo();
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>