<template>
  <models
    :showmodel="showedite"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @open="open"
    @confirm="confirmcreate"
    @closemodel="closemodify"
    class="ifedite"
  >
    <div class="maincontent">
      <el-form
        ref="subform"
        :rules="subformrules"
        :model="subform"
        label-width="auto"
        size="mini"
      >
        <el-form-item label="用户名">
          <el-input
            :disabled="true"
            class="UserId"
            placeholder="请输入"
            clearable
            v-model="subform.Username"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="NickName" v-if="!editepassword" label="昵称">
          <el-input
            class="nickname"
            placeholder="请输入昵称"
            clearable
            v-model="subform.NickName"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password" v-if="editepassword" label="密码">
          <el-input
            type="password"
            class="password"
            placeholder="请输入密码"
            clearable
            v-model="subform.password"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import md5 from "js-md5";
export default {
  props: {
    showedite: {
      type: Boolean,
    },
    editepassword: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const validatepassword = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    const validateNickName = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入昵称"));
      } else {
        callback();
      }
    };
    return {
      subform: {
        UserId: "",
        Username:'',
        NickName: "",
        password: "",
      },
      subformrules: {
        password: [
          { required: true, trigger: "blur", validator: validatepassword },
        ],
        NickName: [
          { required: true, trigger: "blur", validator: validateNickName },
        ],
      },
    };
  },
  components: {
    Models,
  },
  watch: {
    row: function (val) {
      this.subform.NickName = val.NickName;
      this.subform.UserId = val.UserId;
      this.subform.Username = val.Username;
    },
  },
  methods: {
    open() {},
    closemodify() {
      this.$refs.subform.resetFields();
      this.$emit("closemodify");
    },
    confirmcreate() {
      this.$refs.subform.validate((valid) => {
        if (valid) {
          if (this.editepassword) {
            var data = {
              UserId: this.subform.UserId,
              Password: md5(this.subform.password),
            };
            this.$store
              .dispatch("sub/resetsubpass", { ...data })
              .then((res) => {
                if (res.RetCode == 0) {
                  this.$message.success("修改成功");
                  this.closemodify();
                } else {
                  this.$message.error("修改失败");
                }
              });
          } else {
            var data = {
              UserId: this.subform.UserId,
              NickName: this.subform.NickName,
            };
            this.$store
              .dispatch("sub/editeaccount", { ...data })
              .then((res) => {
                if (res.RetCode == 0) {
                  this.$message.success("修改成功");
                  this.closemodify();
                } else {
                  this.$message.error("修改失败");
                }
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ifedite {
  ::v-deep .el-dialog {
    width: 450px;
    height: 307px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 131px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    box-sizing: border-box;
    padding: 20px;
  }
}
</style>