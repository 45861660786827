<template>
  <models
    :showmodel="showdistribute"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @open="open"
    @confirm="confirmdistribute"
    @closemodel="closedistribute"
    class="ifdistribute"
  >
    <!-- <transfer
      ref="transfer"
      :data="transferdata"
      :titles="titles"
      @transferchange="transferchange"
    /> -->
    <tree-transfer
      :title="titles"
      :from_data="treedata"
      :to_data="toData"
      :defaultProps="{ label: 'label' }"
      @add-btn="add"
      @remove-btn="remove"
      :mode="mode"
      height="440px"
      filter
      openAll
    >
    </tree-transfer>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import Transfer from "../../../components/Transfer/index.vue";
import { getCookie } from "../../../utils/auth";
import treeTransfer from "el-tree-transfer";

export default {
  props: {
    showdistribute: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    phoneInfos: {
      type: Array,
    },
  },
  data() {
    return {
      titles: [],
      transferdata: [],
      subphonelist: [],
      treedatavalue: [],
      treedata: [],
      mode: "transfer", // transfer addressList
      toData: [],
      matrixphoneselection: [],
      matrixphoneid: [],
      workGroupId: "",
      workGroup1: [],
      workGroup2: [],
      workGroup3: [],
      workGroup4: [],
    };
  },
  components: {
    Models,
    Transfer,
    treeTransfer,
  },
  watch: {
    row: function (val) {
      this.titles = ["未分配子账号云手机", `子账号${val.Username}`];
    },
  },
  methods: {
    open() {
      this.gettreedata();
    },
    gettreedata() {
      this.toData = [];
      Promise.all([this.getgrouplist(), this.getphonelist()]).then(
        ([Groups, PhoneInfos]) => {
          const filterlist =
            Groups.length &&
            Groups.filter((item, index) => {
              item["pid"] = 0;
              item["label"] = item.Name + "-" + item.MatrixGroupId;
              item["id"] = item.MatrixGroupId;
            });
          this.treedatavalue = Groups;
          this.treedata = this.treedatavalue;
        }
      );
    },
    getgrouplist() {
      return new Promise((resolve, reject) => {
        let data = {
          UserId: getCookie("userId"),
        };
        this.$store
          .dispatch("group/getusermatrixgrouplist", { ...data })
          .then((res) => {
            if (res.RetCode == 0) {
              resolve(res.MatrixGroups);
            }
          });
      });
    },
    getphonelist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("sub/getphonelist").then((res) => {
          if (res.RetCode == 0) {
            let transferdata = res.PhoneInfos.filter((item, index) => {
              if (getCookie("userId") == item.UserId) {
                (item.key = item.Id), (item.label = `${item.Name}(${item.Id})`);
                return item;
              }
            });
            transferdata.sort(function (a, b) {
              return a.label > b.label ? 1 : -1;
            });
            resolve(transferdata);
          }
        });
      });
    },

    getMatrixGroupPhoneList(row) {
      return new Promise(async (resolve, reject) => {
        this.matrixphoneselection = [];
        for (let item of row) {
          let data = {
            MatrixGroupId: item.MatrixGroupId,
          };
          try {
            const res = await this.$store.dispatch("group/getphonelist", {
              ...data,
            });
            if (res.RetCode === 0) {
              this.matrixphoneselection.push(...res.PhoneInfos);
            }
          } catch (error) {}
        }
        resolve(this.matrixphoneselection);
      });
    },

    confirmdistribute() {
      if (this.subphonelist.length == 0) {
        this.$message.error(
          "请先进行选择并移动分发子账号的云手机，再点击确认按钮"
        );
      } else {
        let data = {
          MatrixIds: [],
          PhoneIds: [],
          UserId: this.row.UserId,
          MatrixGroupIds: [],
        };

        this.matrixphoneselection = [];
        for (let item of this.subphonelist) {
          data.MatrixGroupIds.push(item.MatrixGroupId);
        }

        Promise.all([this.getMatrixGroupPhoneList(this.subphonelist)]).then(
          ([PhoneInfos]) => {
            this.workGroup1 = [];
            this.workGroup2 = [];
            this.workGroup3 = [];
            this.workGroup4 = [];
            PhoneInfos.forEach((item) => {
              data.PhoneIds.push(item.Id);
              if (item.TeamGroupId == "0") {
                this.workGroup1.push(item.Id);
              } else if (item.TeamGroupId == "1") {
                this.workGroup2.push(item.Id);
              } else if (item.TeamGroupId == "2") {
                this.workGroup3.push(item.Id);
              } else {
                this.workGroup4.push(item.Id);
              }
            });

            this.$store
              .dispatch("sub/distributematrixphone", { ...data })
              .then((res) => {
                if (res.RetCode == 0) {
                  this.$message.success("已分发至子账号");
                  // this.changeworkgroup();
                  this.closedistribute();
                } else {
                  this.$message.error("分发至子账号失败");
                }
              });
          }
        );
      }
    },

    async changeworkgroup() {
      try {
        let data = {
          UserId: this.row.UserId,
        };
        const res = await this.$store
          .dispatch("group/getmatrixworkgroupid", { ...data })
          .then((res) => {
            if (res.RetCode == 0) {
              this.workGroupId = res.WorkGroupId;
            }
          });
      } catch (error) {}
      let onPhoneIds = [];
      let offPhoneIds = [];

      if (this.workGroupId == "1") {
        onPhoneIds = this.workGroup2;
        offPhoneIds = this.workGroup1.concat(this.workGroup3, this.workGroup4);
      } else if (this.workGroupId == "2") {
        onPhoneIds = this.workGroup3;
        offPhoneIds = this.workGroup1.concat(this.workGroup2, this.workGroup4);
      } else if (this.workGroupId == "3") {
        onPhoneIds = this.workGroup4;
        offPhoneIds = this.workGroup1.concat(this.workGroup2, this.workGroup3);
      } else {
        onPhoneIds = this.workGroup1;
        offPhoneIds = this.workGroup2.concat(this.workGroup3, this.workGroup4);
      }

      // //关闭其他当前

      let offData = {
        PhoneIds: offPhoneIds,
      };
      try {
        const res = await this.$store
          .dispatch("group/poweroffphone", { ...offData })
          .then((res) => {
            if (res.RetCode == 0) {
            //   this.$message.success("关闭成功");
            // } else {
            //   this.$message.error("关闭失败！");
            }
          });
      } catch (error) {}

      //开启
      let onData = {
        PhoneIds: onPhoneIds,
        TeamGroupId: this.workGroupId,
        IsNoChange: "1",
      };
      try {
        const res = await this.$store
          .dispatch("group/poweronphone", { ...onData })
          .then((res) => {
            if (res.RetCode == 0) {
            //   this.$message.success("开启成功");
            // } else {
            //   this.$message.error("开启失败！");
            }
          });
      } catch (error) {}
    },
    closedistribute() {
      this.subphonelist = [];
      // this.$refs.transfer.clearvalue();
      this.$emit("closedistribute");
    },
    transferchange(value, direction, movedKeys) {
      this.subphonelist = value;
      console.log(value, direction, movedKeys);
    },
    // 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
    changeMode() {
      if (this.mode == "transfer") {
        this.mode = "addressList";
      } else {
        this.mode = "transfer";
      }
    },
    // 监听穿梭框组件添加
    add(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      console.log("addfromData:", fromData);
      console.log("asstoData:", toData);
      // console.log("addobj:", obj);

      this.subphonelist = toData;
    },
    // 监听穿梭框组件移除
    remove(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      console.log("removefromData:", fromData);
      console.log("removetoData:", toData);
      // console.log("removeobj:", obj);
      this.subphonelist = this.fromData;
    },
  },
};
</script>
<style lang="scss" scoped>
.ifdistribute {
  ::v-deep .el-dialog {
    width: 750px;
    height: 600px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 420px;
  }
  ::v-deep .el-transfer {
    margin-top: 15px;
    margin-left: 22px;
  }
  ::v-deep .el-transfer-panel {
    width: 242px;
    height: 400px;
  }
  ::v-deep .el-transfer-panel__body {
    height: 360px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    box-sizing: border-box;
    padding: 20px;
  }
}
</style>