<template>
  <models
    :showmodel="showregain"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @open="open"
    @confirm="confirmregin"
    @closemodel="closereginline"
    class="ifregain"
  >
    <transfer
      ref="transfer"
      :data="transferdata"
      :titles="titles"
      @transferchange="transferchange"
    />
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import Transfer from "../../../components/Transfer/index.vue";
export default {
  props: {
    showregain: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    sublineInfos: {
      type: Array,
    },
  },
  data() {
    return {
      titles: [],
      transferdata: [],
      sublinelist: [],
    };
  },
  components: {
    Models,
    Transfer,
  },
  watch: {
    sublineInfos: function (val) {
      let transferdata = val.filter((item, index) => {
        (item.key = item.id), (item.label = `${item.name}(${item.id})`);
        return item;
      });
      this.transferdata = transferdata;
      console.log(this.transferdata);
    },
    row:function(val){
      this.titles = ["已分配子账号线路",`主账号`]
    }
  },
  methods: {
    open() {},
    confirmregin() {
      let data = {
        resourceIds: this.sublinelist,
        userId: this.row.UserId,
      };
      this.$store.dispatch("line/regainline", { ...data }).then((res) => {
        if (res.retCode == 0) {
          this.$message.success("已回收至主账号");
          this.closereginline();
        }else{
           this.$message.error("回收至主账号失败");
        }
      });
    },
    closereginline() {
      this.$refs.transfer.clearvalue();
      this.$emit("closereginline");
    },
    transferchange(value, direction, movedKeys) {
      this.sublinelist = value;
      console.log(value, direction, movedKeys);
    },
  },
};
</script>
<style lang="scss" scoped>
.ifregain {
  ::v-deep .el-dialog {
    width: 750px;
    height: 600px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 420px;
  }
  ::v-deep .el-transfer {
    margin-top: 15px;
    margin-left: 22px;
  }
  ::v-deep .el-transfer-panel {
    width: 242px;
    height: 400px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    box-sizing: border-box;
    padding: 20px;
  }
}
</style>