<template>
  <models
    :showmodel="showdistribute"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @open="open"
    @confirm="confirmdistribute"
    @closemodel="closedistribute"
    class="ifdistribute"
  >
    <!-- <transfer
      ref="transfer"
      :data="transferdata"
      :titles="titles"
      @transferchange="transferchange"
    /> -->
    <tree-transfer
      :title="titles"
      :from_data="treedata"
      :to_data="toData"
      :defaultProps="{ label: 'label' }"
      @add-btn="add"
      @remove-btn="remove"
      :mode="mode"
      height="440px"
      filter
      openAll
    >
    </tree-transfer>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import Transfer from "../../../components/Transfer/index.vue";
import { getCookie } from "../../../utils/auth";
import treeTransfer from "el-tree-transfer";

export default {
  props: {
    showdistribute: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    phoneInfos: {
      type: Array,
    },
  },
  data() {
    return {
      titles: [],
      transferdata: [],
      subphonelist: [],
      treedatavalue: [],
      treedata: [],
      mode: "transfer", // transfer addressList
      toData: [],
    };
  },
  components: {
    Models,
    Transfer,
    treeTransfer,
  },
  watch: {
    row: function (val) {
      this.titles = ["未分配子账号云手机", `子账号${val.Username}`];
    },
  },
  methods: {
    open() {
      this.gettreedata();
    },
    gettreedata() {
      this.toData = [];
      Promise.all([this.getgrouplist(), this.getphonelist()]).then(
        ([Groups, PhoneInfos]) => {
          const filterlist =
            Groups.length &&
            Groups.filter((item, index) => {
              item["children"] = [];
              PhoneInfos.map((eitem, eindex) => {
                if (eitem.GroupId == item.Id && eitem.ProductId != "phone-matrix" && eitem.ProductId != "phone-matrix8" && eitem.ProductId != "phone-matrix16") {
                  eitem["label"] = eitem.Name + "(" + eitem.Id + ")";
                  eitem["pid"] = index + 1;
                  eitem["id"] = eitem.Id;
                  item["pid"] = 0;
                  item["label"] = item.Name;
                  item["children"].push(eitem);
                  item["id"] = index + 1;
                }
              });
              if (item.children.length !== 0) {
                return true;
              } else {
                return false;
              }
            });
          this.treedatavalue = filterlist;
          this.treedata = this.treedatavalue;
        }
      );
    },
    getgrouplist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getgrouplist").then((res) => {
          if (res.RetCode == 0) {
            resolve(res.Groups);
          }
        });
      });
    },
    getphonelist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("sub/getphonelist").then((res) => {
          if (res.RetCode == 0) {
            let transferdata = res.PhoneInfos.filter((item, index) => {
              if (getCookie("userId") == item.UserId) {
                (item.key = item.Id), (item.label = `${item.Name}(${item.Id})`);
                return item;
              }
            });
            transferdata.sort(function (a, b) {
              return a.label > b.label ? 1 : -1;
            });
            resolve(transferdata);
          }
        });
      });
    },
    confirmdistribute() {
      if (this.subphonelist.length == 0) {
        this.$message.error(
          "请先进行选择并移动分发子账号的云手机，再点击确认按钮"
        );
      } else {
        let data = {
          MatrixIds: [],
          PhoneIds: [],
          UserId: this.row.UserId,
        };
        this.subphonelist.forEach((item) => {
          item.children.forEach((eitem) => {
            data.PhoneIds.push(eitem.Id);
          });
        });
        this.$store.dispatch("sub/distributephone", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("已分发至子账号");
            this.closedistribute();
          } else {
            this.$message.error("分发至子账号失败");
          }
        });
      }
    },
    closedistribute() {
      this.subphonelist = [];
      // this.$refs.transfer.clearvalue();
      this.$emit("closedistribute");
    },
    transferchange(value, direction, movedKeys) {
      this.subphonelist = value;
      console.log(value, direction, movedKeys);
    },
    // 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
    changeMode() {
      if (this.mode == "transfer") {
        this.mode = "addressList";
      } else {
        this.mode = "transfer";
      }
    },
    // 监听穿梭框组件添加
    add(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      // console.log("addfromData:", fromData);
      // console.log("asstoData:", toData);
      // console.log("addobj:", obj);

      this.subphonelist = toData;
    },
    // 监听穿梭框组件移除
    remove(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      // console.log("removefromData:", fromData);
      // console.log("removetoData:", toData);
      // console.log("removeobj:", obj);
      this.subphonelist = this.fromData;
    },
  },
};
</script>
<style lang="scss" scoped>
.ifdistribute {
  
  ::v-deep .el-dialog {
    width: 750px;
    height: 600px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 420px;
  }
  ::v-deep .el-transfer {
    margin-top: 15px;
    margin-left: 22px;
  }
  ::v-deep .el-transfer-panel {
    width: 242px;
    height: 400px;
  }
  ::v-deep .el-transfer-panel__body {
    height: 360px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    box-sizing: border-box;
    padding: 20px;
  }


}
</style>