<template>
  <models
    :showmodel="showcreate"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @open="open"
    @confirm="confirmcreate"
    @closemodel="closecreate"
    class="ifcreate"
  >
    <div class="maincontent">
      <el-form
        ref="subform"
        :rules="subformrules"
        :model="subform"
        label-width="auto"
        size="mini"
      >
        <el-form-item prop="username" label="用户名">
          <el-input
            class="username"
            placeholder="请输入用户名"
            clearable
            v-model="subform.username"
          >
          <template #append>@{{ phonenumber }}</template>
          </el-input>
          
        </el-form-item>
        <el-form-item prop="nickname" label="昵称">
          <el-input
            class="nickname"
            placeholder="请输入昵称"
            clearable
            v-model="subform.nickname"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input
            type="password"
            class="password"
            placeholder="请输入密码"
            clearable
            v-model="subform.password"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import md5 from "js-md5";
import { isSubNumber } from "../../../utils/validate";
export default {
  props: {
    showcreate: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    phonenumber: {
      type: String
    }
  },
  data() {
    const validateusername = (rule, value, callback) => {
    
        if (value.length == 0) {
          callback(new Error("请输入用户名"));
        } else {
            if (!isSubNumber(value)){
              callback(new Error("用户名中不能包含中文或特殊字符，请重新输入"));
            }else{
              callback();
            }
        }
    };
    const validatenickname = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入昵称"));
      } else {
        callback();
      }
    };
    const validatepassword = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      subform: {
        username: "",
        nickname: "",
        password: "",
      },
      subformrules: {
        username: [
          {
            required: true,
            trigger: "blur",
            validator: validateusername,
          },
        ],
        nickname: [
          { required: true, trigger: "blur", validator: validatenickname },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatepassword },
        ],
      },
    };
  },
  components: {
    Models,
  },
  // watch: {
  //   phonenumber: function (val) {
  //     console.log(val)
  //     this.phonenumber = val;
  //   },
  // },
  methods: {
    open() {},
    closecreate() {
      this.$refs.subform.resetFields();
      this.$emit("closecreate");
    },
    confirmcreate() {
      this.$refs.subform.validate((valid) => {
        console.log(valid);
        if (valid) {
          let data = {
            Username: this.subform.username,
            NickName: this.subform.nickname,
            Password: md5(this.subform.password),
          };
          this.$store.dispatch("sub/createaccount", { ...data }).then((res) => {

            if (res.RetCode == 0) {
              this.$message.success("创建成功");
              this.closecreate();
            }else{
              this.$message.error(res.Message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ifcreate {
  ::v-deep .el-dialog {
    width: 450px;
    height: 328px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 158px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    box-sizing: border-box;
    padding: 20px;
  }
}
</style>