<template>
  <models :showmodel="showdistribute"
          :title="title"
          :canceltext="canceltext"
          :confirmtext="confirmtext"
          :needcancel="needcancel"
          @open="open"
          @confirm="confirmdistribute"
          @closemodel="closedistributeline"
          class="ifdistribute">
    <transfer ref="transfer"
              :data="transferdata"
              :titles="titles"
              @rendercontent="rendercontent"
              @transferchange="transferchange" />
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import Transfer from "../../../components/Transfer/index.vue";
import { getCookie } from "../../../utils/auth";
export default {
  props: {
    showdistribute: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => { },
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    lineInfos: {
      type: Array,
    },
  },
  data () {
    return {
      titles: [],
      transferdata: [],
      sublinelist: [],
    };
  },
  components: {
    Models,
    Transfer,
  },
  watch: {
    lineInfos: function (val) {
      let transferdata = val.filter((item, index) => {
        if (getCookie("userId") == item.userId) {
          (item.key = item.id), (item.label = `${item.name}(${item.id})`);
          return item;
        }
      });

      this.transferdata = transferdata
    },
    row: function (val) {
      this.titles = ["未分配子账号线路", `子账号${val.Username}`];
    },
  },
  methods: {
    open () { },
    rendercontent (h, option) {
      // console.log(option);
      return (
        <div>
          <span>{option.label}</span>
        </div>
      );
    },
    confirmdistribute () {
      let data = {
        resourceIds: this.sublinelist,
        userId: this.row.UserId,
      };
      this.$store.dispatch("line/distributeline", { ...data }).then((res) => {
        if (res.retCode == 0) {
          this.$message.success("已分发至子账号");
          this.closedistributeline();
        } else {
          this.$message.error("分发至子账号失败");
        }
      });
    },
    closedistributeline () {
      this.sublinelist = [];
      this.$refs.transfer.clearvalue();
      this.$emit("closedistributeline");
    },
    transferchange (value, direction, movedKeys) {
      this.sublinelist = value;
      console.log(value, direction, movedKeys);
    },
  },
};
</script>
<style lang="scss" scoped>
.ifdistribute {
  ::v-deep .el-dialog {
    width: 750px;
    height: 600px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 420px;
  }
  ::v-deep .el-transfer {
    margin-top: 15px;
    margin-left: 22px;
  }
  ::v-deep .el-transfer-panel {
    width: 242px;
    height: 400px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    box-sizing: border-box;
    padding: 20px;
  }
}
</style>