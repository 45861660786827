<template>
  <models :showmodel="editPasswordAll"
          title="统一重置密码"
          :canceltext="canceltext"
          :confirmtext="confirmtext"
          :needcancel="needcancel"
          @open="open"
          @confirm="confirmcreate"
          @closemodel="closeEditPasswordAll"
          class="ifedite">
    <div class="maincontent">
      <el-form ref="subform"
               :rules="subformrules"
               :model="subform"
               label-width="auto"
               size="mini">
        <el-form-item prop="password"
                      label="密码">
          <el-input type="password"
                    class="password"
                    placeholder="请输入密码"
                    clearable
                    v-model="subform.password">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import md5 from "js-md5";
export default {
  props: {
    editPasswordAll: {
      type: Boolean,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    const validatepassword = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      subform: {
        password: "",
      },
      subformrules: {
        password: [
          { required: true, trigger: "blur", validator: validatepassword },
        ],
      },
    };
  },
  components: {
    Models,
  },

  methods: {
    open () { },
    closeEditPasswordAll () {
      this.$refs.subform.resetFields();
      this.$emit("closeEditPasswordAll");
    },
    confirmcreate () {
      this.$refs.subform.validate((valid) => {
        if (valid) {
          var data = {
            Password: md5(this.subform.password),
            All: true
          };
          this.$store
            .dispatch("sub/resetsubpass", { ...data })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$message.success("修改成功");
                this.closeEditPasswordAll();
              } else {
                this.$message.error("修改失败");
              }
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ifedite {
  ::v-deep .el-dialog {
    width: 450px;
    // height: 307px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    // height: 131px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    box-sizing: border-box;
    padding: 20px;
  }
}
</style>