<template>
  <models
    :showmodel="showdistribute"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @open="open"
    @confirm="confirmdistribute"
    @closemodel="closedistribute"
    class="ifdistribute"
  >
    <transfer
      ref="transfer"
      :data="transferdata"
      :titles="titles"
      @transferchange="transferchange"
    />
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import Transfer from "../../../components/Transfer/index.vue";
import { getCookie } from "../../../utils/auth";
export default {
  props: {
    showdistribute: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    phoneInfos: {
      type: Array,
    },
  },
  data() {
    return {
      titles: [],
      transferdata: [],
      subphonelist: [],
    };
  },
  components: {
    Models,
    Transfer,
  },
  watch: {
    phoneInfos: function (val) {
      var matrixdata = [];
      var matrilist = [];
      let transferdata = val.filter((item, index) => {
        if (getCookie("userId") == item.UserId) {
          if (
            item.ProductId == "universal" ||
            item.ProductId == "live" ||
            item.ProductId == "matrix" || item.ProductId == "phone-advanced" ||
            item.ProductId == "phone-shared" ||
            item.ProductId == "phone-exclusive" || item.ProductId == "phone-noip"|| item.ProductId == "phone-social"
          ) {
            (item.key = item.Id), (item.label = `${item.Name}(${item.Id})`);
            return item;
          }
          // if (item.ProductId == "matrix") {
          //   matrixdata.push(item);
          // }
        }
      });
      var list = [];
      matrixdata.forEach((item) => {
        if (matrilist.indexOf(item.MatrixId) == -1) {
          list.push({
            key: item.MatrixId,
            label: "矩阵版",
            MatrixId: item.MatrixId,
            matrilist: [],
            PhoneIds: [],
          });
          matrilist.push(item.MatrixId);
        }
      });
      list.forEach((item) => {
        matrixdata.forEach((eitem) => {
          if (eitem.MatrixId == item.MatrixId) {
            item.matrilist.push(eitem);
            item.PhoneIds.push(eitem.Id);
          }
        });
      });
      this.transferdata = [...transferdata, ...list];
      console.log(this.transferdata);
    },
    row: function (val) {
      this.titles = ["未分配子账号云手机", `子账号${val.Username}`];
    },
  },
  methods: {
    open() {},
    rendercontent(h, option) {//没用到
      // console.log(option);
      if (option.ProductId == "matrix") {
        console.log(option);
        return (
          <div>
            <div>矩阵版</div>
          </div>
        );
      } else {
        return (
          <div>
            <span>{option.label}</span>
          </div>
        );
      }

      // console.log(h)
    },
    confirmdistribute() {
      let data = {
        MatrixIds: [],
        PhoneIds: [],
        UserId: this.row.UserId,
      };
      this.subphonelist.forEach((item) => {
        this.transferdata.forEach((eitem) => {
          if (item == eitem.MatrixId) {
            data.MatrixIds.push(item);
            data.PhoneIds = [...data.PhoneIds, ...eitem.PhoneIds];
          }
          if (item == eitem.Id) {
            data.PhoneIds.push(eitem.Id);
          }
        });
      });
      this.$store.dispatch("sub/distributephone", { ...data }).then((res) => {
        if (res.RetCode == 0) {
          this.$message.success("已分发至子账号");
          this.closedistribute();
        } else {
          this.$message.error("分发至子账号失败");
        }
      });
    },
    closedistribute() {
      this.subphonelist = [];
      this.$refs.transfer.clearvalue();
      this.$emit("closedistribute");
    },
    transferchange(value, direction, movedKeys) {
      this.subphonelist = value;
      console.log(value, direction, movedKeys);
    },
  },
};
</script>
<style lang="scss" scoped>
.ifdistribute {
  ::v-deep .el-dialog {
    width: 750px;
    height: 600px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 420px;
  }
  ::v-deep .el-transfer {
    margin-top: 15px;
    margin-left: 22px;
  }
  ::v-deep .el-transfer-panel {
    width: 242px;
    height: 400px;
  }
  ::v-deep .el-transfer-panel__body {
    height: 360px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    box-sizing: border-box;
    padding: 20px;
  }
}
</style>