<template>
  <models
    :showmodel="showdelete"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @open="open"
    @confirm="confirmdelete"
    @closemodel="closedelete"
    class="ifdelete"
  >
    <div class="warningtitle">
      <p class="warningcontent">是否要删除以下子账号？</p>
    </div>
    <com-table
      tablesize="small"
      tableheight="100"
      :columns="columns"
      :headercellstyle="headercellstyle"
      :data="tabledata"
    />
  </models>
</template>
<script>
import ComTable from "../../../components/Table/index.vue";
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showdelete: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      columns: [
        {
          prop: "UserId",
          label: "用户ID",
          width: "100",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "Username",
          label: "用户名",
          width: "100",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "NickName",
          label: "昵称",
          width: "100",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
      ],
      tabledata: [],
    };
  },
  components: {
    Models,
    ComTable,
  },
  watch: {
    row: function (val) {
      this.tabledata = [val];
    },
  },
  methods: {
    open() {},
    confirmdelete() {
      let data = {
        UserIds: [this.row.UserId],
      };
      this.$store.dispatch("sub/deleteaccount", { ...data }).then((res) => {
        if (res.RetCode == 0) {
          this.closedelete();
        } else if (res.RetCode == 24) {
          this.$message.warning("该账号下存在云手机,请先回收所有云手机");
        }else{
          this.$message.error("删除子账号失败！")
        }
      });
    },
    closedelete() {
      this.$emit("closedelete");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifdelete {
  ::v-deep .el-dialog {
    width: 450px;
    height: 344px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 176px;
  }
  ::v-deep .el-table::before {
    background: none;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    box-sizing: border-box;
    padding: 20px;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>