import { render, staticRenderFns } from "./DeleteSub.vue?vue&type=template&id=021cb821&scoped=true"
import script from "./DeleteSub.vue?vue&type=script&lang=js"
export * from "./DeleteSub.vue?vue&type=script&lang=js"
import style0 from "./DeleteSub.vue?vue&type=style&index=0&id=021cb821&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "021cb821",
  null
  
)

export default component.exports