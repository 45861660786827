<template>
  <models
    :showmodel="showregain"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @open="open"
    @confirm="confirmregin"
    @closemodel="closeregin"
    class="ifregain"
  >
    <!-- <transfer
      ref="transfer"
      :data="transferdata"
      :titles="titles"
      @transferchange="transferchange"
    /> -->
    <tree-transfer
      :title="titles"
      :from_data="treedata"
      :to_data="toData"
      :defaultProps="{ label: 'label' }"
      @add-btn="add"
      @remove-btn="remove"
      :mode="mode"
      height="440px"
      filter
      openAll
    >
    </tree-transfer>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import Transfer from "../../../components/Transfer/index.vue";
import treeTransfer from "el-tree-transfer";
import { getCookie } from "../../../utils/auth";

export default {
  props: {
    showregain: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    subphoneInfos: {
      type: Array,
    },
  },
  data() {
    return {
      titles: [],
      transferdata: [],
      subphonelist: [],
      treedatavalue: [],
      treedata: [],
      mode: "transfer", // transfer addressList
      toData: [],
    };
  },
  components: {
    Models,
    Transfer,
    treeTransfer,
  },
  watch: {
    // subphoneInfos: function (val) {
    //   var matrixdata = [];
    //   var matrilist = [];
    //   let transferdata = val.filter((item, index) => {
    //     if (
    //       item.ProductId == "universal" ||
    //       item.ProductId == "live" ||
    //       item.ProductId == "matrix" ||
    //       item.ProductId == "phone-advanced" ||
    //       item.ProductId == "phone-shared" ||
    //       item.ProductId == "phone-exclusive" ||
    //       item.ProductId == "phone-noip"
    //     ) {
    //       (item.key = item.Id), (item.label = `${item.Name}(${item.Id})`);
    //       return item;
    //     }
    //     // if (item.ProductId == "matrix") {
    //     //   matrixdata.push(item);
    //     // }
    //   });
    //   var list = [];
    //   matrixdata.forEach((item) => {
    //     if (matrilist.indexOf(item.MatrixId) == -1) {
    //       list.push({
    //         key: item.MatrixId,
    //         label: "矩阵版",
    //         MatrixId: item.MatrixId,
    //         matrilist: [],
    //         PhoneIds: [],
    //       });
    //       matrilist.push(item.MatrixId);
    //     }
    //   });
    //   list.forEach((item) => {
    //     matrixdata.forEach((eitem) => {
    //       if (eitem.MatrixId == item.MatrixId) {
    //         item.matrilist.push(eitem);
    //         item.PhoneIds.push(eitem.Id);
    //       }
    //     });
    //   });
    //   this.transferdata = [...transferdata, ...list];

    //   console.log(this.transferdata);
    // },
    row: function (val) {
      this.titles = ["已分配子账号云手机", `主账号`];
    },
  },
  methods: {
    open() {
      this.gettreedata();
    },
    gettreedata() {
      Promise.all([this.getgrouplist(), this.getphonelist()]).then(
        ([Groups, PhoneInfos]) => {
          const filterlist =
            Groups.length &&
            Groups.filter((item, index) => {
              item["children"] = [];
              PhoneInfos.map((eitem, eindex) => {
                if (eitem.GroupId == item.Id && eitem.ProductId != "phone-matrix" && eitem.ProductId != "phone-matrix8" && eitem.ProductId != "phone-matrix16") {
                  eitem["label"] = eitem.Name + "(" + eitem.Id + ")";
                  eitem["pid"] = index + 1;
                  eitem["id"] = eitem.Id;
                  item["pid"] = 0;
                  item["label"] = item.Name;
                  item["children"].push(eitem);
                  item["id"] = index + 1;
                }
              });
              if (item.children.length !== 0) {
                return true;
              } else {
                return false;
              }
            });
          this.treedatavalue = filterlist;
          this.treedata = this.treedatavalue;
          this.toData = [];
        }
      );
    },
    getgrouplist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getgrouplist").then((res) => {
          if (res.RetCode == 0) {
            resolve(res.Groups);
          }
        });
      });
    },
    getphonelist() {
      var subUserId = this.row.UserId;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("group/getphonelist", { subUserId })
          .then((res) => {
            if (res.RetCode == 0) {
              let transferdata = res.PhoneInfos.filter((item, index) => {
                if (subUserId == item.UserId) {
                  (item.key = item.Id),
                    (item.label = `${item.Name}(${item.Id})`);
                  return item;
                }
              });
              transferdata.sort(function (a, b) {
                return a.label > b.label ? 1 : -1;
              });
              resolve(transferdata);
            }
          });
      });
    },
    confirmregin() {
      if (this.subphonelist.length == 0) {
        this.$message.error(
          "请先进行选择并移动回收主账号的云手机，再点击确认按钮"
        );
      } else {
        let data = {
          MatrixIds: [],
          PhoneIds: [],
          UserId: this.row.UserId,
        };
        this.subphonelist.forEach((item) => {
          item.children.forEach((eitem) => {
            data.PhoneIds.push(eitem.Id);
          });
        });
        this.$store.dispatch("sub/regainphone", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("已回收至主账号");
            this.closeregin();
          } else {
            this.$message.error("回收至主账号失败");
          }
        });
      }
    },
    closeregin() {
      // this.$refs.transfer.clearvalue();
      this.$emit("closeregin");
    },
    transferchange(value, direction, movedKeys) {
      this.subphonelist = value;
      console.log(value, direction, movedKeys);
    },
    // 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
    changeMode() {
      if (this.mode == "transfer") {
        this.mode = "addressList";
      } else {
        this.mode = "transfer";
      }
    },
    // 监听穿梭框组件添加
    add(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      // console.log("addfromData:", fromData);
      // console.log("asstoData:", toData);
      // console.log("addobj:", obj);

      this.subphonelist = toData;
    },
    // 监听穿梭框组件移除
    remove(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      // console.log("removefromData:", fromData);
      // console.log("removetoData:", toData);
      // console.log("removeobj:", obj);
      this.subphonelist = this.fromData;
    },
  },
};
</script>
<style lang="scss" scoped>
.ifregain {
  ::v-deep .el-dialog {
    width: 750px;
    height: 600px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 420px;
  }
  ::v-deep .el-transfer {
    margin-top: 15px;
    margin-left: 22px;
  }
  ::v-deep .el-transfer-panel {
    width: 242px;
    height: 400px;
  }
  ::v-deep .el-transfer-panel__body {
    height: 360px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    box-sizing: border-box;
    padding: 20px;
  }
}
</style>