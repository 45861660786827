import { render, staticRenderFns } from "./CreateSub.vue?vue&type=template&id=72db4ee1&scoped=true"
import script from "./CreateSub.vue?vue&type=script&lang=js"
export * from "./CreateSub.vue?vue&type=script&lang=js"
import style0 from "./CreateSub.vue?vue&type=style&index=0&id=72db4ee1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72db4ee1",
  null
  
)

export default component.exports